import React, { useEffect } from "react"
import Layout from "../components/layout"

function ContactPage(props: {}) {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.airtable.com/js/embed/embed_snippet_v1.js";
    script.async = true;
    document.body.appendChild(script);
    document.body.className = "page-contact"
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <Layout>
      <div className="iframe-container mx-2 pt-6 sm:mx-auto sm:w-2/3 max-w-xl">
        <iframe className="relative airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrCNACqxfg41ifig?backgroundColor=green" frameBorder="0" width="100%" height="957" style={{ background: "transparent" }}></iframe>
      </div>
    </Layout>
  )
}



export default ContactPage
